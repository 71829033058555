.emotionButtons {
  display: flex;
  align-items: center;

  .title {
    color: #242424;
    font-size: 15px;
    font-weight: 600;
    margin: 0 20px 0 0;
  }

  .button {
    border-radius: 15px;
    font-size: 10px;
    padding: 3px 15px;
    margin-right: 25px;
    text-transform: capitalize;
    border-style: solid;
    border-width: 1px;
    background-color: #F8F8F8;

    &.felicidad {
      border-color: #63B447;

      &.selected {
        background-color: #63B447;
        color: #FFFFFF
      }
    }

    &.miedo {
      border-color: #D77843;

      &.selected {
        background-color: #D77843;
        color: #FFFFFF
      }
    }

    &.enojo {
      border-color: #CB4641;

      &.selected {
        background-color: #CB4641;
        color: #FFFFFF
      }
    }

    &.disgusto {
      border-color: #453477;

      &.selected {
        background-color: #453477;
        color: #FFFFFF
      }
    }

    &.tristeza {
      border-color: #4282B4;

      &.selected {
        background-color: #4282B4;
        color: #FFFFFF
      }
    }
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section {
  min-height: 220px;
}
