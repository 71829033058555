.lider-title{
	font-size: 20px;
	line-height: 24px;
}

.lider-itemContainer{
	padding-top: 5px;
	padding-bottom: 15px;
	padding-left: 26px !important;
	padding-right: 26px !important;
}
.lider-dataContainer{
	padding-top: 5px;
	padding-bottom: 15px;
	padding-right: 10px !important;
	padding-left: 10px !important;
}
.lider-headDataContainer{
	padding-top: 15px;
	padding-bottom: 0px;
	padding-right: 10px !important;
	padding-left: 10px !important;
}
.lider-itemResult{
	background-color: #F8F8F8;
	border-radius: 18px;
	
}
.lider-itemName{
	font-size: 11px;
	display: inline-block;
	padding-right: 5px;
	line-height: 20px;
}
.lider-itemDesc{
	font-size: 11px;
	display: inline-block;
}
.lider-itemCont{
	font-size: 11px;
	line-height: 15px;
}
.lider-wordTag{
	list-style: none;
	margin: 0;
	padding: 0;
}
.lider-wordTag li{
	display: inline-block;
	padding: 3px 10px;
	background-color: #3C9946;
	color: #fff;
	font-size: 11px;
	margin-right: 10px;
	margin-bottom: 5px;
}
.linkViewMore{
	font-size: 11px;
	display: inline-block;
	padding-right: 10px;
	text-decoration: underline;
	color: #1C5EA9;
}

.table-base{
	/*background-color: #F8F8F8;*/
}
.table-row-item td:first-child{
	border-top-left-radius: 6px;
	border-bottom-left-radius: 12px;
}
.table-row-item td:last-child{
	border-top-right-radius: 6px;
	border-bottom-right-radius: 12px;
}
.table-row-item .cell-item{
	background-color: #F8F8F8;
	border-bottom: solid 12px #DCE5EF !important;
	border-top: none;
	border-left: none;
	border-right: none;
}
.table-head-item{
	background-color: #DCE5EF;
	border: none !important;
}
.TableContainer{
	padding-top: 40px;
}

.form-container{
	padding-bottom: 50px;
}
.registro{
	height: 700px;
}