/*
Este archivo contiene las clases genericas, 
creadas y modificadas para el proyecto
a fin de tener todo concentrado en un solo archivo,
evitando reescribir.
*/


/*------------HTML elements------------*/
/* a {
    color: #ffffff !important;
}
a:hover {
    color: #ffffff;
    text-decoration: unset;
} */
p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}
select{
    border: 1px solid #cccccc !important;
}

/*--------------Size Width------------*/
.w20{width: 20px;}
.w100{width: 100px;}
.w20p{width: 20%;}
.w100p{width: 100% !important;}

/*--------------Size Height------------*/
.h200{height: 200px;}
.h300{height: 300px; border: 1px solid #cccccc;}

/*--------------Paddings------------*/
.p0{padding: 0 !important;}
.p10{padding: 10px;}
.p20{padding: 20px;}
.p40{padding: 40px;}
.pl600{padding-left: 400px;}
.pl0{padding-left: 0px !important;}
.pl10{padding-left: 10px !important;}
.pl20{padding-left: 20px !important;}
.pr0{padding-right: 0px !important;}
.pl5p{padding-left: 5% !important;}
.pr10{padding-right: 10px !important;}
.pr15{padding-right: 15px !important;}
.pr30{padding-right: 30px !important;}
.pt10{padding-top: 10px;}
.pt25{padding-top: 25px;}
.pt30{padding-top: 30px;}
.pt230{padding-top: 230px;}
.pt10p{padding-top: 10%;}
.pb10{padding-bottom: 10px;}
.pb25{padding-bottom: 25px;}
.pb35{padding-bottom: 35px !important;}
.pb40{padding-bottom: 40px !important;}
.pb50{padding-bottom: 50px;}
.pb230{padding-bottom: 230px;}
.pb5p{padding-bottom: 5%;}

/*---------------Margins------------*/
.ml10{margin-left: 10px;}
.mt20{margin-top: 20px;}
.mt70{margin-top: 70px;}
.mb60{margin-bottom: 60px;}
.mb10{margin-bottom: 10px !important;}

/*-------------Text Class-----------*/
.txt_white{color: #ffffff;}
.txt_left{text-align: left;}
.txt_random{color: #000000;}
.txt-center{text-align: center;}
.txt-end{text-align: end;}

.ftn15{font-size: 15px !important;}
.ftn13{font-size: 13px;}
.ftn11{font-size: 11px;}
.ftn10{font-size: 10px;}
.ftn9{font-size: 9px;}
.fnt20{font-size: 20px !important;}

.txt_bold{font-weight: bold;}

.section-title{
	font-size: 20px;
	line-height: 24px;
}
.paragraph-text{
    height: 410px;
    overflow-y: auto;
}

.linkT{
    text-decoration: underline;
}

.btn-green{background-color: #75CE46 !important;}
.btn-gray{background-color: #BCBCBC !important;}
.btn-gray2{background-color: #F8F8F8 !important;}
.btn-red{background-color: #CC1F55 !important;}
.btn-blue{background-color: #1C5EA9 !important;}
.btn-white{background-color: #ffffff !important;}
.btnRegister a{
    color: #1C5EA9 !important;
}

/*-------------Background-----------*/
.back-gray{
    background-color: #F8F8F8;
}


/*--------------Aligns------------*/
.float-r{float: right;}
.justCont-flexE{justify-content: flex-end;}
.justCenter{justify-content: center;}
.alignStart{align-items: flex-start;}
.alignCenter{align-items: center;}


/*--------------Display------------*/
.d-flex{display: flex;}


/*--------------Borders------------*/
.borderpt{
    border-style: dashed; 
    border-width: 4px;
}
.borderR-15{border-radius: 15px;}
.borderT{
    border-radius: 15px;
    background-color: #F8F8F8;
}
.border_familia{
    border: 2px solid #215568;
}


/*-------------Other Class----------*/
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    border: none !important;
    border-radius: .25rem;
}
.card-header {
    background-color: transparent !important;
    border-bottom: none !important;
}

.table thead th {
    vertical-align: initial;
    border-bottom: none;
}
.table-bordered td, .table-bordered th {
    border: none;
}
.table tbody td{
    vertical-align: inherit;
}
th {
    font-weight: normal;
    text-align: -internal-center;
}
#correctOption{
    border-bottom: 1px solid green;
    border-radius: 0px;
    background: transparent;
}
.img-doc{
    width: 100%;
    max-height: 500px;
    border: 1px solid #cccccc;
    overflow: auto;
}
.inputValid{
    background-color: #F8F8F8;
    font-size: 11px;
    padding: 10px;
    border-radius: 6px;
}
.inputNewClient{
    background: transparent !important;
    border-bottom: 3px solid #75CE46 !important;
    border-radius: 0 !important;
}
.inputNewClient:focus{
    box-shadow: none !important;
}

.tableNewDashboard, 
.tableNewDashboard td,
.tableNewDashboard th{
    border: none !important;
}
.tableNewDashboard td, .tableNewDashboard th{
    padding: 0.25rem 0.75rem !important;
}
.contComparative{
    display: flex;
    padding: 10px 0 !important;
}
/* .borderB1{border-bottom: 2px solid #55A2A5;}
.borderB2{border-bottom: 2px solid #E3B12C;}
.borderB3{border-bottom: 2px solid #DF5753;}
.borderB4{border-bottom: 2px solid #DF5753;}
.borderB5{border-bottom: 2px solid #936088;}
.borderB6{border-bottom: 2px solid #196DB5;}
.borderB7{border-bottom: 2px solid #E3B12C;}
.borderB8{border-bottom: 2px solid #55A2A5;}
.borderB9{border-bottom: 2px solid #55A2A5;}
.borderB10{border-bottom: 2px solid #55A2A5;} */
.borderB1{border-bottom: 2px solid #3464AA;}
.borderB2{border-bottom: 2px solid #A84188;}
.borderB3{border-bottom: 2px solid #E79C71;}
.borderB4{border-bottom: 2px solid #AAC869;}
.borderB5{border-bottom: 2px solid #D95F56;}
.borderB6{border-bottom: 2px solid #FFCE00;}
.borderB7{border-bottom: 2px solid #D96231;}
.borderB8{border-bottom: 2px solid #D43A71;}
.borderB9{border-bottom: 2px solid #70589C;}
.borderB10{border-bottom: 2px solid #24A7E0;}
.borderB11{border-bottom: 2px solid #57A3FF;}
.borderB12{border-bottom: 2px solid #57A3FF;}
.border_felicidad{border-bottom: 2px solid #7CB347;}
.border_miedo{border-bottom: 2px solid #D77843;}
.border_enojo{border-bottom: 2px solid#CB4641;}
.border_disgusto{border-bottom: 2px solid #453477;}
.border_tristeza{border-bottom: 2px solid #4282B4;}

.contSummary, .contEmotion{
    padding: 0.75rem 2.25rem;
}

.termometro {
    font-size: 10px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .barra {
    width: 100px;
    height: 100px;
    border-radius: 6em;
    background-color: #BCBCBC;
    position: absolute;
    top: 2.5em;
    overflow: hidden;
    margin-top: -25px;
  }
  .nivel {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .nivel.Alegria{background-color: #63B447;}
  .alturaAlegria{height: 50%}
  .nivel.Felicidad{height: 50%; background-color: #63B447;}
  .nivel.Enojo{height: 0%; background-color: red;}
  .nivel.Tristeza{height: 50%; background-color: #2983B9;}
  .nivel.Disgusto{height: 50%; background-color: #49317B;}
  .nivel.Miedo{height: 0%; background-color: red;}

.contSlider{
    padding: 20px;
    background: #F8F8F8;
    font-size: 11px;
    border-radius: 8px;
    overflow-y: auto;
    height: 300px;
}
.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000 !important;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: left !important;
    padding-left: 40px !important;
    margin-left: 0 !important;
    list-style: none;
    width: 100%;
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.linkContext{
    color:#1C5EA9 !important;
}

.sunburg svg{
    width: 120% !important;
    height: auto !important;
}

.symb{
    width: 15px;
    height: 15px;
    border-radius: 25px;
}

.sunburg text {
    font-family: sans-serif;
    font-size: 20px !important;
    dominant-baseline: middle;
    text-anchor: middle;
    pointer-events: none;
    fill: #222;
}

.contTermsLine{
    height: 20px;
    width: 100%;
    background-color: #94A1AD;
}

.backColorAlta{background-color: #f7e491;}
.backColorMediaAlta{background-color: #f7e491;}
.backColorMediaTipica{background-color: #a6c0da;}
.backColorMediaBaja{background-color: #a9d4e4;}
.backColorBaja{background-color: #d3e1be;}

.backColor1{background-color: #A7822D;}
.backColor2{background-color: #B44F59;}
.backColor3{background-color: #802B5E;}
.backColor4{background-color: #1B713A;}
.backColor5{background-color: #00566A;}
.backColor6{background-color: #4D4522;}
.backColor7{background-color: #8FB4C7;}
.backColor8{background-color: #8DCED4;}
.backColor9{background-color: #C2DAAE;}
.backColor10{background-color: #BCBCBC;}
.backColor11{background-color: #63B447;}
.backColor12{background-color: #49317B;}

.contTermsTematic{
    border: 2px solid #A8555B;
    background-color: #F8F8F8;
    border-radius: 5px;
}

.contNewDashboard .carousel-control-next-icon,
.contNewDashboard .carousel-control-prev-icon,
.contextFlechas .carousel-control-next-icon,
.contextFlechas .carousel-control-prev-icon{
	background-color: transparent !important;
}

.contNewDashboard .carousel-control-next-icon,
.contextFlechas .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}
.contNewDashboard .carousel-control-prev-icon,
.contextFlechas .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    color: #000 !important;
    font-weight: bold;
}

.contextFlechas .carousel-control-next, .contextFlechas .carousel-control-prev{
    width: 2%;
}
g{
    fill-opacity: 1 !important;
}

.spinner{
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: center;
}
