.relatedTerms {
  display: flex;
  flex-direction: column;
  height: 100%;

  h4 {
    font-size: 14px;
    text-align: left;
    color: #242424;
    margin-bottom: 0;
  }

  .dashboard {
    width: 100%;
    height: 350px;
  }
}

.foundWord {
  font-weight: 700;
  background-color: inherit;
  padding: 0;
}