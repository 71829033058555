body{
	height: 100vh;
}

.entregableMainContainer{
	max-width: 600px;
	min-width: 250px;
	width: 100%;
	padding: 150px 15px;
	display: block;
	margin: 0 auto;
}
.entregableTitle{
	font-size: 20px;
}
.BtnComenzar{
	background-color: #1C5EA9;
	border: none;
	color: #fff;
	padding: 10px 20px;
	border-radius: 5px;
	line-height: 1.3em;
}
.textDescription{
	padding: 20px 0px;
}