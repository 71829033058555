.bodyLogin{
	background-repeat: no-repeat !important;
	background-color: transparent !important;
	background-size: cover !important;
	background-position: center center !important;
}

.auth0-lock-header-bg{
	background: #EAF1F5 !important;
}
.auth0-lock-header-bg-blur{
	background-image: none !important;
}

a {
	color: rgba(92,102,111,0.6) !important;
}