.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;

  .bg1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 958px;
    height: 516px;
    z-index: -1;
  }

  .bg2 {
    position: absolute;
    bottom: 40vh;
    left: 50px;
    width: 338px;
    height: 306px;
    z-index: -1;
  }

  .bg3 {
    position: absolute;
    top: 0;
    right: 0;
    width: 779px;
    height: 410px;
    z-index: -1;
  }

  .header {
    margin-bottom: 100px;

    .buttons {
      a {
        color: #1C5EA9 !important;
        margin-right: 24px;
        font-size: 15px;
      }

      button {
        border: none;
        background: none;
        color: #707070;
        font-size: 15px;
        text-decoration: underline;
      }
    }

    .logo {
      width: 128px;
      height: 101px;
    }
  }

  .main {
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 40px;
      color: #243780;
      width: 100%;
      text-align: center;
    }

    p {
      font-size: 16px;
      color: #243780;
      margin-bottom: 25px !important;
      width: 100%;
      text-align: center;
    }

    .search {
      margin-bottom: 50px;

      .searchInput {
        height: 47px;
        width: 100%;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 6px;
        font-size: 14px;
        padding: 0px 16px;
        border: none;
        background: #F8F8F8;
      }

      .filtersButton {
        height: 47px;
        background: #79A7CB;
        color: #fff;
        border: none;
        border-radius: 4px;
        box-shadow: 0px 3px 6px #00000029;
        text-transform: none;
        width: 100%;
      }

      .searchButton {
        width: 100%;
        height: 38px;
        background: #75BA4C 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 66px;
        text-transform: none;
        font-size: 14px;
      }
    }

    .tickets {
      display: flex;
      width: 100%;
      
      p {
        color: #242424;
        font-size: 13px;
        font-weight: bold;
        width: 430px;
      }

      .questions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        button {
          background-color: #55A2A5;
          color: #fff;
          font-size: 12px;
          border: none;
          margin: 0 12px 4px;
          padding: 4px 8px;
        }
      }
    }

    .filters {
      border: 1px solid #707070;
      border-radius: 10px;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);

      h3 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}

.answers {
  margin-top: 80px !important;

  .paper {
    padding: 28px 32px;
    border-radius: 8px;
    margin-top: 30px;

    .group {
      margin-bottom: 18px;
    }
  }

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: #75BA4C;
    }
  }

  h3 {
    color: #243780;
    font-size: 15px;
    font-weight: bold;
  }
}

.error {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #000 !important;
  }

  .errorDescription {
    font-weight: bold;
    margin-bottom: 15px !important;
  }

  button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
  }
}