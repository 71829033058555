.title {
  font-size: 20px;
  font-weight: 600;
  color: #243780;
  text-align: center;
}

.subtitle {
  font-size: 12px;
  text-align: center;
  margin-bottom: 24px;
}

.dashboard {
  width: 100%;
  height: 100vh;
}

.examples {
  margin-bottom: 40px;

  h4 {
    color: #243780;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 32px;
  }
}

.topics {
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 20px;

  .list {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;

    .category {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    .color {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      display: inline-block;
    }

    .description {
      margin-left: 5px;
      margin-right: 20px;
      text-transform: capitalize;
    }
  }
}

.search {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;

  .header {
    justify-content: center;
  }

  h4 {
    color: steelblue;
    text-align: center;
    margin-bottom: 32px;
  }
}

.docsFound {
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: white !important;
  background-color: #243780;
  cursor: default;
  font-size: 12px;
}

.section {
  min-height: 220px;
}

.selectedTopic {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  b {
    font-size: 14px;
    margin-left: 35px;
    margin-right: 10px;
  }

  button {
    color: white;
    margin-left: 5px;
    padding: 4px;
    border-radius: 2px;
    font-size: 11px;
    padding: 5px 10px;
    border: none;
    margin-right: 15px;
    text-transform: capitalize;
  }
}

.carousel {
  width: 100%;

  .row {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.foundWord {
  font-weight: 700;
  background-color: inherit;
  padding: 0;
}