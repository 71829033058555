html, body, #root, .App {
    height: 100%;
}
.full-width{
    margin-right: -15px;
    margin-left: -15px;
}
.bg-welcome{
	background: url('../img/bg_welcome.png')no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
	text-align: center;
	background-position: center;
}
.bg-shadow{
	background: rgb(0,0,0,0.5);
	width: 100%;
    height: 100%;
}
