.send-button {
    border: none;
    border-radius: 30px;
    font-weight: 600;
    font-size: 12px;
    width: 150px;
    color: #fff;
    transition: all 0.2s ease-out;
    padding: 5px 15px;
}

.is-validated {
    opacity: 0;
    height: 0;
    overflow: hidden;
    right: 0;
    z-index: 1;
    transition: opacity 0.7s ease-out;
}

.validated-box {
    background-color: white;
    font-size: 11px;
    border-bottom: #75CE46 solid 2px;
    padding-right: 20px;
    padding-left: 5px;
    padding-bottom: 5px;
    min-width: 180px;
}

.round-button{
	border: none;
    border-radius: 50%;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    font-weight: 600;
    transition: all 0.2s ease-out;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 5px;
}

.card-content{
    display: flex !important;
    flex-direction: column !important;
    overflow: visible !important;
}

.card-content .row{
    margin: 0px !important;
}

.card-header .row{
    margin: 0px !important;
}

.obj-info {
    background-color: #F8F8F8;
    font-size: 11px;
    border-radius: 6px;
    min-width: 180px;
    border: none;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem
}

.obj-info-large {
    background-color: #F8F8F8;
    font-size: 11px;
    border-radius: 6px;
    min-width: 227px;
    border: none;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem
}

.mr-10 {
    margin-left: 10px !important;
}

.select-width {
    max-width: 200px;
}

.hidden {
    display: none !important;
}

.max-width-25{
    max-width: 25% !important;
}

.max-width-100{
    max-width: 100% !important;
}

.validation-form{
    display: flex !important;
    justify-content: space-around;
    max-width: 60% !important;
    margin: auto;
    margin-bottom: 50px !important;
}

.validation-button{
    border: none;
    border-radius: 66px;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 8px 15px;
    font-weight: 600;
    width: 200px;
}
.validation-button-small{
    border: none;
    border-radius: 66px;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 8px 15px;
    font-weight: 600;
    width: 130px;
}
.alert{
    width: 245px;
    min-height: 50px;
    font-size: 12px;
    text-align: center;
}
.answer{
    position: relative;
    right: 25px;
}
.center-row{
    justify-content: center;
}

.center-column{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.image{
    width: 100%;
    height: 100%;
}

.boxRed{
    border: 3px solid red;
    position: absolute;
    bottom: 0;
    left: 0;
}

.unknown{
    margin-left: 25px;
}
.context-button-small{
    border: none;
    border-radius: 66px;
    color: #1C5EA9;
    box-shadow: 0px 0px 0px ;
    padding: 0px 0px;
    font-weight: 600;
    width: 98px;
}