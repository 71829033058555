.bg-search-principal{
	background: url('../img/bg_search.png')no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: auto;
	text-align: center;
	background-position: center;
}
.bg-shadow{
	background: rgb(0,0,0,0.5);
	width: 100%;
}
.bg-shadow .card-header label{
	color:#ffffff;
}
.bg-shadow #searchTitle{
	color: #ffffff;
}
.busqueda-title{
	font-size: 20px;
	line-height: 24px;
}
#SearchQuery{
	width: 100%;
	margin-right: 10px;
}
#SearchButton{
	padding: 5px 35px !important;
}
.inline-label{
	display: inline-block !important;
}
.busqueda-itemContainer{
	/*padding-top: 15px;
	padding-bottom: 15px;*/
	border: none !important;
}
.busqueda-itemResult{
	background-color: #F8F8F8;
	border-radius: 18px;
	padding: 24px 20px;
	margin-left: 0px !important;
	margin-right: 0px !important;
}
.busqueda-itemName{
	font-size: 11px;
	display: inline-block;
	padding-right: 5px;
	line-height: 20px;
}
.busqueda-itemDesc{
	font-size: 11px;
	display: inline-block;
	word-break: break-all;
}
.busqueda-itemCont{
	font-size: 11px;
	line-height: 15px;
	/*max-height: 105px;
	min-height: 105px;
	height: 100%;
	overflow-y: scroll;*/
}
.busueda-wordTag{
	list-style: none;
}
.busueda-wordTag li{
	display: inline-block;
	padding: 3px 10px;
	background-color: #3C9946;
	color: #fff;
	font-size: 11px;
	margin-right: 10px;
	margin-bottom: 5px;
}

input.form-control,
select.custom-select{
	background-color: #F8F8F8;
	border-radius: 6px;
	border: none;
	font-size: 11px;
}
input.form-control:hover,
select.custom-select:hover,
input.form-control:active,
select.custom-select:active,
input.form-control:focus,
select.custom-select:focus{
	outline: none !important;
}

.busqueda-table{
	font-size: 11px;
}
.table-base{
	/*background-color: #F8F8F8;*/
}
.table-row-item td:first-child{
	border-top-left-radius: 6px;
	border-bottom-left-radius: 12px;
}
.table-row-item td:last-child{
	border-top-right-radius: 6px;
	border-bottom-right-radius: 12px;
}
.table-row-item .cell-item{
	background-color: #F8F8F8;
	border-bottom: solid 12px #DCE5EF !important;
	border-top: none;
	border-left: none;
	border-right: none;
}
.table-head-item{
	background-color: #DCE5EF;
	border: none !important;
}
.TableContainer{
	padding-top: 40px;
	padding-bottom: 100px;
}

.searchWindowContainer{
	/*max-height: 650px;
	overflow-y: auto;*/
	padding-top: 20px;
}

.tagCointainer{
	padding-top: 15px;
}
.inputTerms{
	min-width: 180px;
	width: 100% !important;
}
.selectProj{
	min-width: 150px;
}
.filterButton{
	background-color: #40B5D3;
	border: none;
	border-radius: 6px;
	color: #fff;
	box-shadow: 0px 3px 6px #00000029;
	padding: 5px 15px;
	font-weight: 600;
	transition: all 0.2s ease-out;
}
.SearchButton{
	background-color: #75CE46;
	border: none;
	border-radius: 66px;
	color: #fff;
	box-shadow: 0px 3px 6px #00000029;
	padding: 5px 15px;
	font-weight: 600;
	transition: all 0.2s ease-out;
}
.SearchButtonDesactive{
	background-color: #cccccc;
	border: none;
	border-radius: 66px;
	color: #fff;
	box-shadow: 0px 3px 6px #00000029;
	padding: 5px 15px;
	font-weight: 600;
	transition: all 0.2s ease-out;

}
.SearchButtonDesactive:hover,
.SearchButton:hover,
.SearchButton:active,
.SearchButton:focus{
	background-color: #3C9946;
	transition: all 0.2s ease-out;
}

.exportCSV{
	display: inline-block;
	padding-right: 20px;
}
.exportCSV a{
	cursor: pointer;
}
.lblchkbx{
	line-height: 20px;
	padding-right: 15px;
	margin-bottom: 0px;
	font-size: 13px;
}
.imageResult,
.textResult{
	transition: all 0.5s ease-out;
}
.img-carousel{
	max-height: 200px;
	display: block;
	margin: 0 auto;
}
.noresultContariner{
	padding-top: 100px;
}
.noresultContariner .title{
	font-size: 40px;
	line-height: 50px;
	color: #1C5EA9;
	font-weight: 900;
}
.noresultContariner .subtitle{
	font-size: 20px;
	line-height: 25px;
}
.FilterContainer{
	padding-right: 25px;
	padding-top: 5px;
	padding-bottom: 5px;
}
.form-inline label{
	font-size: 13px;
	line-height: 20px;
}
.CarouselContainer{
	width: 100%;
}
.carousel-control-next-icon,
.carousel-control-prev-icon{
	background-color: #1C5EA9 !important;
}
.iconPageNext,
.iconPagePrev{
	cursor: pointer;
	padding-left: 10px;
	padding-right: 10px;
}

.button-prev{
	transform: rotateY(180deg);
}
.iconPageNext{}

.iconArrowCarousel{
	width: 25px;
	height: 25px;
}
.searchTerm button{
	float: right;
}
.back_filter{
	background: #F8F8F8;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 6px;
}
.filter-title{
	font-size: 13px;
}
.MuiInputBase-root{
	padding: 2px 5px;
    background-color: #F8F8F8;
    border-radius: 6px;
    font-size: 11px !important;
    border: 1px solid #cccccc !important;
    margin-top: 2px;
}
.MuiInput-underline:before {
    border-bottom: none !important;
}
.MuiInput-underline:after {
    border-bottom: none !important;
}

@media (min-width: 576px){
	.back_filter .mr-sm-2, .back_filter .mx-sm-2 {
    	margin-right: 2.5rem!important;
	}
}

/*Section NewProyect*/

.registerProyect select{
	width: 100% !important;
    max-width: 360px;
}
.btnRegister{
	background-color: transparent;
    border: none;
	border-bottom: 1px solid #75CE46;
    border-radius: 0;
    box-shadow: 0px ;
    padding: 5px 15px;
    font-weight: 600;
    transition: all 0.2s ease-out;
	color: #1C5EA9;
}
.btnRegister:hover{
	border-bottom: 1px solid #cccccc;
}
.moreButton .filterButton {
    border-radius: 26px !important;
    padding: 5px 12px;
	background-color: #4648ce;
}
#filterBtn{
	margin-top: 4px;
    margin-left: 4px;
    padding: 2px;
}