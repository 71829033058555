body {
  margin: 0;
  font-family: Work Sans;
}
.font-thin{
	font-weight: 100;
}
.font-extralight{
	font-weight: 200;
}
.font-regular{
	font-weight: 400;
}
.font-medium{
	font-weight: 600;
}
.font-semibold{
	font-weight: 700;
}
.font-bold{
	font-weight: 900;
}
.img-icon{
	width: 17px;
	height: 17px;
	display: block;
}
.icon-container{
	display: inline-block;
	padding: 3px 5px;
}
.icon-container a,
.icon-container img{
	cursor: pointer !important;
}
.table-container{
	background-color: #DCE5EF;
	border-radius: 18px;
	padding: 15px;
}
.table-base{
	margin-bottom: 0px !important;
}


#LoaderContainer{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
  animation: fade 1s;
}
/*Loader*/
#Loader{
	width: 80px;
	height: 80px;
	margin: 0 auto;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #3C9946;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
/*END Loader*/