.nav-bitacora{
	padding: 34px 28px 30px 28px !important;
	width: 100%;
}
.navbar-item{
	color: #1C5EA9 !important;
	font-size: 20px;
	line-height: 24px;
	border-bottom: solid 2px #fff;
	padding: 3px 10px 15px 10px !important;
	margin: 0px 15px;
	transition: all 0.3s ease-out;
}
.navbar-item.selected,
.navbar-item:hover,
.navbar-item:active,
.navbar-item:focus{
	border-bottom: solid 2px #3C9946;
	transition: all 0.3s ease-out;
}
.main-logo{
	max-width: 130px;
}
.btn-nav{
	color: #fff !important;
	background-color: #1C5EA9;
	border-radius: 66px;
	display: inline-block !important;
	padding: 0px 10px !important;
	font-size: 11px;
	line-height: 40px;
	max-height: 40px;
	min-width: 200px;
	margin-top: -5px;
}

.BtnLogout{
	color: #707070;
	font-size: 15px;
	text-decoration: underline;
	display: block;
	cursor: pointer;
	line-height: 40px;
	margin-top: -10px;
	padding: 0px 15px;
	transition: all 0.3s ease-out;
}
.BtnLogout:hover,
.BtnLogout:active,
.BtnLogout:focus{
	color: #1C5EA9;
	transition: all 0.3s ease-out;
}