.card {
  font-size: 12px;
  line-height: 1;
  border-width: 2px;
  border-style: solid;

  .loader {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  p {
    margin-top: 7px;
    margin-bottom: 7px !important;
    display: flex;

    b {
      margin-right: 5px;
    }
  }

  .text {
    display: flex;
    max-height: 100px;
    height: 100px;
    text-align: left;
    overflow-y: scroll;
  }

  .foundWord {
    font-weight: 700;
    background-color: inherit;
    padding: 0;
  }

  .wordWrapper {
    display: flex;
    align-items: center;
    margin-top: 7px;

    .word {
      color: white;
      margin-left: 5px;
      padding: 4px;
      border-radius: 2px;
      font-size: 11px;
      padding: 5px 10px;
      border: none;
      margin-right: 15px;
      text-transform: capitalize;
    }
  }
}