.busqueda-title{
	font-size: 20px;
	line-height: 24px;
}
input.form-control,
select.custom-select{
	background-color: #F8F8F8;
	border-radius: 6px;
	border: none;
	font-size: 11px;
}
input.form-control:hover,
select.custom-select:hover,
input.form-control:active,
select.custom-select:active,
input.form-control:focus,
select.custom-select:focus{
	outline: none !important;
}
.BtnCargar{
	max-width: 250px;
	width: 100%;
	display: block;
	margin: 0 auto;
	color: #fff;
	background-color: #707070;
	padding: 11px 17px;
	font-size: 11px;
	border: none;
	border-radius: 66px;
}
.ItemsDropZoneContainer{
	padding: 100px 0px;
}
.DropZoneContainer{
	border: 2px dashed #707070;
	border-radius: 22px;
}
.imgIcoUpload{
	width: 41px;
	height: 41px;
	margin: 0 auto;
}
.SelectFileContainer{
	padding: 15px 0px;
}
.SelectFile{
	max-width: 150px;
	width: 100%;
	display: block;
	margin: 0 auto;
	color: #fff;
	background-color: #3C9946;
	padding: 5px 15px;
	font-size: 11px;
	border: none;
	border-radius: 66px;
}