.loader {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #F8F8F8;
  border-radius: 15px;

  p {
    margin-right: 10px;
  }

  div {
    display: contents;
    position: initial;

    div {
      display: block;
    }
  }
}

.error {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #F8F8F8;
  border-radius: 15px;

  p {
    display: flex;
    align-items: center;
  }

  button {
    text-transform: none;
    text-decoration: underline;
    color: black;
  }
}
